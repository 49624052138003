import vrt from '../assets/img/game/15/d15.jpg'
import kuhinja from '../assets/img/game/15/k15.jpg'
import outItem1 from '../assets/img/game/14/vrt/1.png'
import outItem2 from '../assets/img/game/14/vrt/2.png'
import outItem3 from '../assets/img/game/14/vrt/3.png'
import outItem4 from '../assets/img/game/14/vrt/4.png'
import outItem5 from '../assets/img/game/14/vrt/5.png'
import p1 from '../assets/img/game/15/vrt/20204-Boje-za-jaja@2x.webp'
import k1 from '../assets/img/game/15/kuhinja/Aroma_Vanilija_2411 (1).png'
import k2 from '../assets/img/game/15/kuhinja/Bourbon_Vanilin_HR.png'
import k3 from '../assets/img/game/15/kuhinja/Modlice Zečja obitelj-6.png'
import k4 from '../assets/img/game/15/kuhinja/Modlice za kekse u obliku jajeta s motivima zeca i tulipana.png'
import k5 from '../assets/img/game/15/kuhinja/recept7.png'

const day_15 = [
    {   main: [
            {
                id: 0,
                image: vrt
            },
            {
                id: 1,
                image: kuhinja
            }
        ],
        outside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "5deg", pitch: "-20.1deg" },
                size: { width: 280, height: 809 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "-87deg", pitch: "-42.1deg" },
                size: { width: 20, height: 25 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "64.2deg", pitch: "-28.5deg" },
                size: { width: 20, height: 24 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-115.3deg", pitch: "-27.4deg" },
                size: { width: 20, height: 26 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "139.8deg", pitch: "-36.8deg" },
                size: { width: 26, height: 30 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-44.4deg", pitch: "-20.2deg" },
                size: { width: 20, height: 24 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
        ],
        inside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "-310.2deg", pitch: "-25.1deg" },
                size: { width: 203, height: 650 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "16.1deg", pitch: "-19.1deg" },
                size: { width: 26, height: 54 },
                product_title: 'Aroma vanilije u bočici',
                product_description: 'S Dr. Oetker aromom vanilije vaši će kolači i deserti jednostavno postati zavodljiviji.',
                product_image: k1,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/dodaci-za-kolace/arome/aroma-vanilije-u-bocici'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "-12deg", pitch: "-13.5deg" },
                size: { width: 45, height: 55 },
                product_title: 'Bourbon vanilin šećer',
                product_description: 'Dr. Oetker Bourbon vanilin šećer daje nježni miris i fini okus prave bourbon vanilije šlagovima, tijestima i nadjevima za kolače.',
                product_image: k2,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/dodaci-za-kolace/vanilin-secer/bourbon-vanilija-secer'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-191deg", pitch: "-54.6deg" },
                size: { width: 190, height: 195 },
                product_title: 'Modlice za kekse Zečja obitelj – 3 kom',
                product_description: 'Ovog Uskrsa pomoću modlica “Zečja obitelj” napravite najkreativnije keksiće za obitelj i prijatelje! Keksiće možete izrezati modlicama u tri različite veličine i ukrasiti ih po želji.',
                product_image: k3,
                product_url: 'https://oetker-shop.hr/proizvod/modlice-za-kekse-zecja-obitelj-3-kom/'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-106.5deg", pitch: "-32.4deg" },
                size: { width: 180, height: 180 },
                product_title: 'Modlice za kekse u obliku jajeta s motivima zeca i tulipana',
                product_description: 'Unesite dašak proljeća u vaš dom! Ovog Uskrsa pomoću modlica u obliku jajeta s motivom zečića i tulipana napravite najkreativnije keksiće za obitelj i prijatelje.',
                product_image: k4,
                product_url: 'https://oetker-shop.hr/proizvod/modlice-za-kekse-u-obliku-jajeta-s-motivima-zeca-i-tulipana/'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-37.1deg", pitch: "-35deg" },
                size: { width: 140, height: 140 },
                product_title: 'Cupcakes s ovčicama',
                product_description: 'Ako volite čokoladu, napravite ove čokoladne muffine s tučenim vrhnjem i beze komadićima s motivom ovčica',
                product_image: k5,
                product_url: 'https://www.oetker.hr/hr-hr/recept/r/cupcakes-s-ovcicama'
            },
        ]
    },
]

export default day_15